<template>
  <div>
    <div v-if="spreadBuy && spreadSell && finalBuyTax && finalSellTax">
      <TVChartContainer
        :symbol="'WEBOOSWAP'"
        :interval="'1'"
        :bTax="finalBuyTax"
        :sTax="finalSellTax"
        :themeDefault="this.$route.params.themes"
        :sBuy="spreadBuy"
        :sSell="spreadSell"
        :tokenBase="this.$route.params.id"
        :network="this.$route.params.network"
        :dex="this.$route.params.dex"
        :currency="this.$route.params.currency"
        :usd="this.$route.params.usd"
        :symbolusd="this.$route.params.symbolusd"
      ></TVChartContainer>
    </div>
    <div
      class="large-indicator"
      style="
        justify-content: center;
        -webkit-justify-content: center;
        height: 100vh;
        width: 100%;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
      "
      v-else
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import TVChartContainer from "../components/TVChartContainer";
import axios from "axios";
import * as Bitquery from "../components/TVChartContainer/Bitquery";
export default {
  name: "Home",
  components: { TVChartContainer },
  created() {},
  mounted() {
    this.getSlippage();
  },
  data() {
    return {
      lastPrice: 0,
      spreadBuy: "",
      spreadSell: "",
      finalBuyTax: "",
      finalSellTax: "",
    };
  },
  methods: {
    getSlippage() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      var timestampbefore = tomorrow.toISOString();

      axios
        .post(
          Bitquery.endpoint,
          {
            query: `
            {
                ethereum(network: ${this.$route.params.network}) {
                dexTrades(
                    options: {asc: "timeInterval.minute"}
                    date: {since: "${tomorrow}", till: "${timestampbefore}"}
                    exchangeName: {is: "${this.$route.params.dex}"}
                    any: [{baseCurrency: {is: "${this.$route.params.id}"}, quoteCurrency: {is: "${this.$route.params.currency}"}}, {baseCurrency: {is: "${this.$route.params.currency}"}, quoteCurrency: {is: "${this.$route.params.usd}"}}]
                    tradeAmountUsd: {gt: 10}
                ) {
                    timeInterval {
                    minute(count: 60, format: "%Y-%m-%dT%H:%M:%SZ")
                    __typename
                    }
                    baseCurrency {
                    symbol
                    __typename
                    }
                    quoteCurrency {
                    symbol
                    __typename
                    }
                    volume: quoteAmount
                    high: quotePrice(calculate: maximum)
                    low: quotePrice(calculate: minimum)
                    open: minimum(of: block, get: quote_price)
                    close: maximum(of: block, get: quote_price)
                    __typename
                }
                __typename
                }
            }
            `,
            variables: {
              from: new Date("2021-12-20T07:23:21.000Z").toISOString(),
              to: new Date("2023-12-23T15:23:21.000Z").toISOString(),
              // interval: Number(resolution),
              // tokenAddress: symbolInfo.ticker,
            },
            mode: "cors",
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-API-KEY": "BQYzdg4qWl8l8P6KXwHiJvPPlQLAoriA",
            },
          }
        )
        .then((response) => {
          // get slippage
          axios
            .get(
              "https://api-scanner.blocksafu.com/tax/" +
                this.$route.params.id +
                "?chainid=56"
            )
            .then((res) => {
              const datas = res.data;
              let buyTax;
              let sellTax;
              buyTax = datas.buy_tax;
              sellTax = datas.sell_tax;
              this.finalBuyTax = buyTax;
              this.finalSellTax = sellTax;
            });
          // get spread
          const bars = response.data.data.ethereum.dexTrades;
          this.lastPrice = Number(bars[bars.length - 1].close);
          this.spreadBuy = this.lastPrice / 1;
          this.spreadSell = this.lastPrice / 1;
        });
    },
  },
};
</script>

<style scoped>
</style>
