<template>
  <div id="">
    <div class="TVChartContainer" id="tv_chart_container" />
  </div>
</template>

<script>
import api from "./api/index";
import axios from "axios";
import * as Bitquery from "./TVChartContainer/Bitquery";

export default {
  name: "TVChartContainer",
  props: {
    tokenBase: {
      default: "0x9a093ddcae05496a05ac76d96d49890b528c8ce0",
      type: String,
    },
    themeDefault: {
      default: "Light",
      type: String,
    },
    network: {
      default: "ethereum",
      type: String,
    },
    currency: {
      default: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      type: String,
    },
    dex: {
      default: "Pancake v2",
      type: String,
    },
    usd: {
      default: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      type: String,
    },
    sBuy: {
      type: Number,
    },
    sSell: {
      type: Number,
    },
    bTax: {
      type: String,
    },
    sTax: {
      type: String,
    },
    symbolusd: {
      default: "USDC",
      type: String,
    },
    symbol: {
      default: "WEBOOSWAP",
      type: String,
    },
    interval: {
      default: "1h",
      type: String,
    },
    containerId: {
      default: "tv_chart_container",
      type: String,
    },
    datafeedUrl: {
      default: "https://demo-feed-data.tradingview.com",
      type: String,
    },
    libraryPath: {
      default: "/charting_library/charting_library/",
      type: String,
    },
    chartsStorageUrl: {
      default: "https://saveload.tradingview.com",
      type: String,
    },
    chartsStorageApiVersion: {
      default: "1.2",
      type: String,
    },
    clientId: {
      default: "tradingview.com",
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    studiesOverrides: {
      type: Object,
    },
  },
  tvWidget: null,
  data() {
    return {
      baseCurrency: "0x9a093ddcae05496a05ac76d96d49890b528c8ce0",
      addressInfo: {},
      searchValue: "",
      getSearchAddress: [],
      getAllAddressValue: [],
      showSearchChild: false,
    };
  },
  async mounted() {
    // condition theme color
    let color;
    if (this.themeDefault === "Dark") {
      color = "#171E29";
    } else {
      color = "#FFFFFF";
    }
    // condition buy
    let buy;
    if (this.sBuy) {
      const first = (this.sBuy * this.bTax) / 1;
      const second = this.sBuy + first;
      const end = second.toFixed(18);
      buy = end;
    }
    // condition sell
    let sell;
    if (this.sSell) {
      const firstSell = (this.sSell * this.sTax) / 1;
      const secondSell = this.sSell - firstSell;
      const endSell = secondSell.toFixed(18);
      sell = endSell;
    }
    const widgetOptions = {
      symbol: this.symbol,
      datafeed: api(
        this.tokenBase,
        this.network,
        this.dex,
        this.currency,
        this.usd,
        this.symbolusd
      ),
      interval: "5",
      container_id: this.containerId,
      library_path: this.libraryPath,
      theme: this.themeDefault,
      toolbar_bg: color,
      custom_css_url: "/charting_library/charting_library/style.css",
      locale: "en",
      disabled_features: ["use_localstorage_for_settings"],
      charts_storage_url: this.chartsStorageUrl,
      enabled_features: [],
      charts_storage_api_version: this.chartsStorageApiVersion,
      client_id: this.clientId,
      user_id: this.userId,
      fullscreen: this.fullscreen,
      autosize: this.autosize,
      studies_overrides: this.studiesOverrides,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      currency_code: ["USD", "BNB"],
      overrides: {
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": color,
        // "mainSeriesProperties.candleStyle.downColor": "#3E414E",
        // "mainSeriesProperties.candleStyle.borderDownColor": "#3E414E",
        // "mainSeriesProperties.candleStyle.wickDownColor": "#3E414E",

        // "mainSeriesProperties.candleStyle.downColor": "#3E94EE",
        // "mainSeriesProperties.candleStyle.borderDownColor": "#3E94EE",
        // "mainSeriesProperties.candleStyle.wickDownColor": '#3E94EE'

        // "mainSeriesProperties.candleStyle.downColor": "#151924",
        // "mainSeriesProperties.candleStyle.borderDownColor": "#151924",
        // "mainSeriesProperties.candleStyle.wickDownColor": '#151924'
      },
      spreadBuy: buy,
      spreadSell: sell,
    };
    if (this.sBuy == widgetOptions.spreadBuy) {
      const tvWidget = new TradingView.widget(widgetOptions);
      tvWidget.onChartReady(function () {});
    } else {
      const tvWidget = new TradingView.widget(widgetOptions);
      tvWidget.onChartReady(function () {
        // console.log(widgetOptions)
        var position = tvWidget
          .chart()
          .createPositionLine()
          // .onReverse(function (text) {})
          // .onClose(function (text) {})
          .setText("Spread Buy")
          .setBodyBackgroundColor("rgba(12, 181, 91, 0.8)")
          .setBodyBorderColor("rgba(12, 181, 91, 0.8)")
          .setBodyTextColor("rgba(255, 255, 255, 1)")
          .setQuantity("")
          .setLineColor("green")
          .setLineLength(3);
        // position.setPrice(position.getPrice() - position.getPrice()-(14/100));
        position.setPrice(widgetOptions.spreadBuy);
        // console.log("position" + spreadBuy)

        var positionSell = tvWidget
          .chart()
          .createPositionLine()
          // .onReverse(function (text) {})
          // .onClose(function (text) {})
          .setText("Spread Sell")
          .setBodyBackgroundColor("rgba(232, 8, 27, 0.8)")
          .setBodyBorderColor("rgba(232, 8, 27, 0.8)")
          .setBodyTextColor("rgba(255, 255, 255, 1)")
          .setQuantity("")
          .setLineColor("red")
          .setLineLength(3);
        // position.setPrice(position.getPrice() - 2);
        positionSell.setPrice(widgetOptions.spreadSell);

        // var order = tvWidget.chart().createOrderLine()
        // 	.onCancel(function(text) {
        // 	})
        // 	.setText("Slip Page Sell")
        //   .setLineColor('rgb(232, 8, 27)')
        //   .setBodyBackgroundColor('rgba(232, 8, 27, 0.8)')
        //   .setBodyBorderColor('rgba(232, 8, 27, 0.8)')
        //   .setBodyTextColor('rgba(255, 255, 255, 1)')
        // 	.setLineLength(3)
        // 	.setQuantity("")
        //   .setCloseButtonBackgroundColor('rgba(232, 8, 27, 0.8)')
        //   .setCloseButtonBorderColor('rgb(232, 8, 27)')
        //   .setCloseButtonIconColor('rgb(255, 255, 255)');
        // // order.setPrice(order.getPrice() - 2.5);
        // order.setPrice(0.000000390);

        // tvWidget
        //   .chart()
        //   .createExecutionShape()
        //   .setText("@1,320.75 Limit Buy 1")
        //   .setTextColor("rgba(255,0,0,0.5)")
        //   .setArrowSpacing(25)
        //   .setArrowHeight(25)
        //   .setDirection("buy")
        //   .setArrowColor("#F00")
        //   .setTime(new Date("8 June 2022 10:00:00 GMT+0").valueOf() / 1000)
        //   .setPrice(0.0000004);
      });
    }
  },
  methods: {
    getCoinInfo() {
      const query = `
      {
          ethereum(network: bsc) {
          dexTrades(
              options: {desc: ["block.height", "transaction.index"], limit: 1}
              exchangeAddress: {is: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"}
              baseCurrency: {is: "${this.baseCurrency}"}
              quoteCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"}
          )
          {
              block {
              height
              timestamp {
                  time(format: "%Y-%m-%d %H:%M:%S")
              }
              }
              transaction {
              index
              }
              baseCurrency {
              name
              symbol
              decimals
              }
              quotePrice
          }
          }
      }
      `;
      const url = "https://graphql.bitquery.io/";
      const opts = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": "BQYzdg4qWl8l8P6KXwHiJvPPlQLAoriA",
        },
        body: JSON.stringify({
          query,
        }),
      };
      fetch(url, opts)
        .then((res) => res.json())
        .then((response) => {
          this.addressInfo = response.data.ethereum.dexTrades[0].baseCurrency;
        })
        .catch(console.error);
    },
  },
  computed: {
    cssProps() {
      return {
        "--bg-color": this.themeDefault === "Light" ? "#FFFFFF" : "#171e29",
      };
    },
  },
};
</script>

<style>
.TVChartContainer {
  position: absolute;
  width: 100%;
  height: 100%;
}

:root:not(.theme-dark) {
  --tv-color-platform-background: #ffffff;
  --tv-color-pane-background: #ffffff;
  --tv-color-toolbar-button-background-hover: #f5f5f5;
  --tv-color-toolbar-button-background-secondary-hover: red;
  --tv-color-toolbar-button-background-expanded: #ffffff;
  --tv-color-toolbar-button-text: #a7b6bd;
  --tv-color-toolbar-button-text-hover: #333333;
  --tv-color-toolbar-button-text-active: #31cb9e;
  --tv-color-toolbar-button-text-active-hover: #31cb9e;
  --tv-color-item-active-text: #333333;
  --tv-color-toolbar-toggle-button-background-active: #31cb9e;
  --tv-color-toolbar-toggle-button-background-active-hover: #31cb9e;
}

.theme-dark:root {
  --tv-color-platform-background: #171e29;
  --tv-color-pane-background: #171e29;
  --tv-color-toolbar-button-background-hover: #171e29;
  --tv-color-toolbar-button-background-secondary-hover: red;
  --tv-color-toolbar-button-background-expanded: #40505a;
  --tv-color-toolbar-button-text: #6c7284;
  --tv-color-toolbar-button-text-hover: #efefef;
  --tv-color-toolbar-button-text-active: #31cb9e;
  --tv-color-toolbar-button-text-active-hover: #31cb9e;
  --tv-color-item-active-text: #ffffff;
  --tv-color-toolbar-toggle-button-background-active: #31cb9e;
  --tv-color-toolbar-toggle-button-background-active-hover: #31cb9e;
}

body {
  --action-primary-color: rgba(0, 159, 227, 1);
  --alert-watched-color: rgba(255, 255, 0, 1);
  --warning-new-color: rgba(255, 0, 175, 1);
}

body {
  background-color: var(--bg-color);
  box-sizing: border-box;
  font-size: 16px;
}
.large-indicator {
  display: flex;
  display: -webkit-flex;
}

.large-indicator div {
  height: 1em;
  margin: 8px;
  text-align: center;
  width: 1em;
}

.large-indicator div {
  animation-duration: 0.9s, 2.7s;
  -webkit-animation-duration: 0.9s, 2.7s;
  animation-name: blink, rainbow;
  -webkit-animation-name: blink, rainbow;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.large-indicator div:nth-of-type(2) {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

.large-indicator div:nth-of-type(3) {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rainbow {
  0% {
    background-color: var(--action-primary-color);
  }

  33% {
    background-color: var(--action-primary-color);
  }

  33.5% {
    background-color: var(--warning-new-color);
  }

  66.5% {
    background-color: var(--warning-new-color);
  }

  67% {
    background-color: var(--alert-watched-color);
  }

  100% {
    background-color: var(--alert-watched-color);
  }
}

@-webkit-keyframes rainbow {
  0% {
    background-color: var(--action-primary-color);
  }

  33% {
    background-color: var(--action-primary-color);
  }

  33.5% {
    background-color: var(--warning-new-color);
  }

  66.5% {
    background-color: var(--warning-new-color);
  }

  67% {
    background-color: var(--alert-watched-color);
  }

  100% {
    background-color: var(--alert-watched-color);
  }
}
</style>
