<template>
    <TVChartContainer :symbol="'BITQUERY'" :interval="'1'"></TVChartContainer>
</template>

<script>
import TVChartContainer from "../components/TVChartContainer";
export default {
    name: "TradingView",
    components: {TVChartContainer}
}
</script>

<style scoped>

</style>