import axios from 'axios';
import * as Bitquery from './../TVChartContainer/Bitquery';

const configurationData = {
    supported_resolutions: ['1','5','15','30','60','1D', '1W', '1M']
};

export default(baseCurrency, network, dex, currency, usd, symbolusd, timestampbefore) => ({
    onReady: (callback) => {
        setTimeout(() => callback(configurationData));
    },

    resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) =>{
        const response = await axios.post(
            Bitquery.endpoint, {
                query: `
                {
                  ethereum(network: ${network}) {
                    dexTrades(
                      options: {desc: ["block.height", "transaction.index"], limit: 1}
                      exchangeName: {is: "${dex}"}
                      baseCurrency: {is: "${baseCurrency}"}
                      quoteCurrency: {is: "${currency}"}
                    ) 
                    {
                      block {
                        height
                        timestamp {
                          time(format: "%Y-%m-%d %H:%M:%S") 
                        }
                      }
                      transaction {
                        index
                      }
                      baseCurrency {
                        name
                        symbol
                        decimals
                      }
                      quoteCurrency {
                        name
                        symbol
                        decimals
                      }
                      quotePrice
                    }
                  }
                }
                `,
                variables: {
                    "tokenAddress": symbolName
                },
                mode: 'cors',
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "X-API-KEY": "BQYzdg4qWl8l8P6KXwHiJvPPlQLAoriA"
                }
            }
        );

        const coin = response.data.data.ethereum.dexTrades[0].baseCurrency;
        const coin1 = response.data.data.ethereum.dexTrades[0].quoteCurrency;

        if(!coin){
            onResolveErrorCallback();
        }else{
            const symbol = {
                ticker: symbolName,
                name: `${coin.symbol}/${coin1.symbol}`,
                session: '24x7',
                timezone: 'Etc/UTC',
                minmov: 1,
                pricescale: 1000000000,
                has_intraday: true,
                intraday_multipliers: ['1', '5', '15', '30', '60'],
                has_empty_bars: false,
                has_weekly_and_monthly: false,
                supported_resolutions: configurationData.supported_resolutions,
                volume_precision: 8,
                data_status: 'streaming',
                currency_code: ["USD"],
            }
            onSymbolResolvedCallback(symbol)
        }
    },

    getBars: async(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) =>{
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate()+1);
            timestampbefore = tomorrow.toISOString();
        try{
            if (resolution==='1D') {
                resolution = 1440;
            }
            const response2 = await axios.post(Bitquery.endpoint, {
                query: `
                  {
                    ethereum(network: ${network}) {
                      dexTrades(
                        options: {asc: "timeInterval.minute"}
                        date: {since: "2021-12-29T14:45:00.000Z", till: "${timestampbefore}"}
                        exchangeName: {is: "${dex}"}
                        any: [{baseCurrency: {is: "${baseCurrency}"}, quoteCurrency: {is: "${currency}"}}, {baseCurrency: {is: "${currency}"}, quoteCurrency: {is: "${usd}"}}]
                        tradeAmountUsd: {gt: 10}
                      ) {
                        timeInterval {
                          minute(count: 60, format: "%Y-%m-%dT%H:%M:%SZ")
                          __typename
                        }
                        baseCurrency {
                          symbol
                          __typename
                        }
                        quoteCurrency {
                          symbol
                          __typename
                        }
                        volume: quoteAmount
                        high: quotePrice(calculate: maximum)
                        low: quotePrice(calculate: minimum)
                        open: minimum(of: block, get: quote_price)
                        close: maximum(of: block, get: quote_price)
                        __typename
                      }
                      __typename
                    }
                  }
                `,
                variables: {
                    "from": new Date("2021-12-20T07:23:21.000Z").toISOString(),
                    "to": new Date("2023-12-23T15:23:21.000Z").toISOString(),
                    "interval": Number(resolution),
                    "tokenAddress": symbolInfo.ticker
                },
                mode: 'cors',

            }, {
                headers: {
                    "Content-Type": "application/json",
                    "X-API-KEY": "BQYzdg4qWl8l8P6KXwHiJvPPlQLAoriA"
                }
            })

            const bars = response2.data.data.ethereum.dexTrades.map(el => ({
                time: new Date(el.timeInterval.minute).getTime(), // date string in api response
                low: el.low,
                high: el.high,
                open: Number(el.open),
                close: Number(el.close),
                volume: el.volume,
                quoteCurrency: el.quoteCurrency.symbol,
                baseCurrency: el.baseCurrency.symbol
            }))

            function MyFilter(value, index, array) {
              return response2.data.data.ethereum.dexTrades[index].baseCurrency.symbol == "WBTC";
            }

            function MyFilter2(value, index, array) {
              return response2.data.data.ethereum.dexTrades[index].quoteCurrency.symbol == symbolusd;
            }

            const bars1 = bars.filter(MyFilter);

            const bars2 = bars.filter(MyFilter2);

            const bars3 = bars2.filter(MyFilter3)

            // console.log("aaa", bars1[1].time)
            
            function MyFilter3(value, index, array, bars1, bars2) {
              let webooBnb = bars.filter(MyFilter);
              let bnbBusd = bars.filter(MyFilter2);
              

              // if(webooBnb[0].time == bnbBusd[index].time){
              //   return 
              // }
              // let tempSa = [];
              // for (var i = 0; i < 2; i++) {
              //   let tempD = {};
              //   tempD.time = new Date(webooBnb[i].time).getTime();
              //   tempSa.push(tempD);
              // }
              // console.log("aaa", tempSa)
              // if(a == b) {
              //   return response2.data.data.ethereum.dexTrades[index];
              // }
            }

            // let usds = ""
            // if (network === 'ethereum') {
            //   usds.push('USDC')
            // } else {
            //   usds.push('BUSD')
            // }
            // console.log(network)

            let tempSwapsData = [];
            for (var i = 0; i < bars1.length; i++) {
              let tokenDecimal = 1;
              let tempData = {};
              tempData.time = new Date(bars1[i].time).getTime();
              tempData.high = bars1[i].high * bars2[bars2.length-1].high * tokenDecimal;
              tempData.low = bars1[i].low * bars2[bars2.length-1].low * tokenDecimal;
              tempData.open = bars1[i].open * bars2[bars2.length-1].open * tokenDecimal;
              tempData.close = bars1[i].close * bars2[bars2.length-1].close * tokenDecimal;
              tempData.volume = bars1[i].volume;
              tempSwapsData.push(tempData);
            }
            // console.log("ini tempswap",tempSwapsData)
            // console.log("ini bars1",bars1)
            // console.log("ini bars2",bars2)
            // console.log("ini bars3",bars3)

            let swapsData1 = response2.data.data.ethereum.dexTrades;
            let tempSwapsData1 = [];
            if (swapsData1 && swapsData1.length > 0) {
                const filteredData = swapsData1.filter((value) => value.quoteCurrency.symbol != symbolusd);
                if (filteredData && filteredData.length > 0) {
                    for (var i = 0; i < filteredData.length; i++) {
                        const filteredBUSD = swapsData1.filter((value) => value.quoteCurrency.symbol == symbolusd && value.timeInterval.minute == filteredData[i].timeInterval.minute);
                        if (filteredBUSD && filteredBUSD.length > 0) {
                            let tokenDecimal = 1;
                            let tempDataN = {};
                            tempDataN.time = new Date(filteredData[i].timeInterval.minute).getTime();
                            tempDataN.high = filteredData[i].high * filteredBUSD[filteredBUSD.length - 1].high * tokenDecimal;
                            tempDataN.low = filteredData[i].low * filteredBUSD[filteredBUSD.length - 1].low * tokenDecimal;
                            tempDataN.open = filteredData[i].open * filteredBUSD[filteredBUSD.length - 1].open * tokenDecimal;
                            tempDataN.close = filteredData[i].close * filteredBUSD[filteredBUSD.length - 1].close * tokenDecimal;
                            tempDataN.volume = filteredData[i].volume;
                            tempSwapsData1.push(tempDataN);
                        }
                    }
                }
                // return [tempSwapsData1, tempSwapsData1.length];
                // console.log("arrayNew", tempSwapsData1);
            }

            if (bars.length){
              onHistoryCallback(bars, {noData: false});
            }else{
                onHistoryCallback(bars, {noData: true});
            }
            

        } catch(err){
            console.log({err})
        }
    },
    
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
      console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
    },
    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    },
})
